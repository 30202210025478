<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
    value: {},
    withText: {
        type: Boolean,
        default: false,
    },
});

const roundedRating = computed(() => {
    return Math.round(props.value * 2) / 2;
});

const text = computed(() => {
    if (props.value == null) {
        return t('No rating');
    }
    if (roundedRating.value < 1.5) {
        return t('Very bad');
    } else if (roundedRating.value < 2.5) {
        return t('Bad');
    } else if (roundedRating.value < 3.5) {
        return t('Average');
    } else if (roundedRating.value < 4.5) {
        return t('Good');
    } else {
        return t('Very Good');
    }
});
</script>
<template>
    <div class="flex items-stretch gap-1">
        <div
            v-for="i in 5"
            :key="i"
        >
            <span v-if="roundedRating == i - 0.5">
                <svg
                    height="16"
                    viewBox="0 0 12 12"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        id="star_half_FILL0_wght400_GRAD0_opsz48"
                        d="M10,7.135V12l2.055,1.56L11.23,10.96l1.89-1.23H10.855ZM6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Z"
                        transform="translate(-4 -4)"
                        fill="pink"
                    />
                </svg>
            </span>
            <span v-else-if="roundedRating >= i">
                <svg
                    height="16"
                    viewBox="0 0 12 12"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        id="star_FILL0_wght400_GRAD0_opsz48"
                        d="M6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Z"
                        transform="translate(-4 -4)"
                        fill="pink"
                    />
                </svg>
            </span>
            <span v-else-if="roundedRating < i">
                <svg
                    height="16"
                    viewBox="0 0 12 12"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        id="grade_FILL0_wght400_GRAD0_opsz48"
                        d="M10,10.345ZM6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Zm1.65-2.445L10,12l2.055,1.56L11.23,10.96l1.89-1.23H10.855L10,7.135l-.855,2.6H6.88l1.89,1.23Z"
                        transform="translate(-4 -4)"
                        fill="pink"
                    />
                </svg>
            </span>
        </div>

        <template v-if="withText">
            <span class="flex items-end px-4 font-bold"><span v-html="text" /></span>
        </template>
    </div>
</template>
